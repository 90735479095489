import { Suspense, useEffect } from 'react';
import { Providers } from '../../layout/Providers';
import LoadingContainer from '../LoadingContainer';
import { AllRoutes } from '../Routes';

export const App = () => {
  return (
    <Suspense
      fallback={
        <LoadingContainer loading={true}>
          <></>
        </LoadingContainer>
      }
    >
      <Providers>
        <AllRoutes />
      </Providers>
    </Suspense>
  );
};
